import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import { Box, Tabs, Tab, Link, Typography } from "@mui/material";
import NotFound from "./utils/NotFound";
import Profile from "./settings/profile";
import Connections from "./settings/connections";
import Patreon from "./settings/patreon";

export default function Settings(props) {
  const { user } = props;
  const { subPath } = useParams();

  useEffect(() => {
    if (user === undefined) return;
    document.title = `Settings - ${user.display_name}`;
  }, [user]);

  if (user === undefined) return <></>;
  if (user === null) return (window.location.href = "/");

  return (
    <SimpleBar style={{ minHeight: 0, height: "100%" }}>
      <Box sx={{ m: 5 }}>
        <Typography variant="h4" fontWeight={600}>
          Settings
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={subPath}>
            <Tab label="Profile" value="profile" href="/settings/profile" component={Link} />
            <Tab label="Connections" value="connections" href="/settings/connections" component={Link} />
            {user.patreon && <Tab label="Patreon" value="patreon" href="/settings/patreon" component={Link} />}
          </Tabs>
        </Box>
        {subPath === "profile" ? <Profile user={user} /> : subPath === "connections" ? <Connections user={user} /> : subPath === "patreon" && user.patreon ? <Patreon user={user} /> : <NotFound />}
      </Box>
    </SimpleBar>
  );
}
