import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomLink from "../utils/CustomLink";
import { Highlight, SubSection } from "./text";

export default function TermsOfService() {
  useEffect(() => {
    document.title = "Terms Of Service - Hype";
  }, []);

  return (
    <Box sx={{ ml: 2, mr: 2 }}>
      <Box sx={{ m: "0 auto", maxWidth: "100rem", p: 1 }}>
        <Box sx={{ mt: 3, textAlign: "center", mb: 3 }}>
          <Typography variant="h3" fontWeight={600}>
            Terms of Service
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "54rem", m: "0 auto 50px" }}>
          <Box sx={{ color: "hsl(0 0% 100%/.8)!important", textAlign: "justify", fontWeight: "400", lineHeight: "2rem", letterSpacing: "0.5px", verticalAlign: "baseline" }}>
            <Typography mt={2} variant="body2">
              Welcome to hype.lol (together with Content, the “<Highlight>Site</Highlight>
              "). The Site, together with the Hype, App, and services are collectively referred to as the “<Highlight>Services</Highlight>"
            </Typography>
            <Typography mt={2} variant="body2">
              Please read the following Terms of Service carefully before installing, downloading and/or using the Hype software application (the '<Highlight>App</Highlight>') or using this Site or
              the Services so that you are aware of your legal rights and obligations with respect to Hype, Overpowered, and any other member of the Hype group ('<Highlight>Hype</Highlight>', '
              <Highlight>we</Highlight>', '<Highlight>our</Highlight>' or '<Highlight>us</Highlight>').
            </Typography>
            <Typography mt={2} variant="body2">
              By accessing or using the Services, you expressly acknowledge and agree that you are entering a legal agreement with us and have understood and agree to comply with, and be legally bound
              by, these Terms of Services, together with the Privacy Policy (the '<Highlight>Terms</Highlight>'). You hereby waive any applicable rights to require an original (non-electronic)
              signature or delivery or retention of non-electronic records, to the extent not prohibited under applicable law. If you do not agree to be bound by these Terms please do not access or
              use the Services.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>1 Modification.</Highlight> We reserve the right, at our discretion, to change these Terms at any time. Such change will be effective five (5) days following posting of the
              revised Terms on the Site, and your continued use of the Services thereafter means that you accept those changes.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>2 Ability to Accept Terms.</Highlight> The Services are only intended for individuals aged thirteen (13) years or older. If you are under 13 years please do not visit or use
              the Services. If you are between 13 and 18 years of age, then you must review these Terms with your parent or guardian before visiting or using the Services to make sure that you and
              your parent or guardian understand these Terms and agree to them.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>3 Services Access.</Highlight> For such time as these Terms are in effect, we hereby grant you permission to visit and use the Services provided that you comply with these
              Terms and applicable law.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>4 Restrictions.</Highlight> You shall not: (i) copy, distribute or modify any part of the Services without our prior written authorization; (ii) use, modify, create derivative
              works of, transfer (by sale, resale, license, sublicense, download or otherwise), reproduce, distribute, display or disclose Content (defined below), except as expressly authorized
              herein; (iii) disrupt servers or networks connected to the Services; (iv) use or launch any automated system (including without limitation, 'robots' and 'spiders') to access the
              Services; and/or (v) circumvent, disable or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content or that
              enforce limitations on use of the Services.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>5 Account.</Highlight> In order to use some of the services of the Site, App, you may have to create an account ('Account'). You agree not to create an Account for anyone else
              or use the account of another without their permission. When creating your Account, you must provide accurate and complete information. You are solely responsible for the activity that
              occurs in your Account, and you must keep your Account password secure. You must notify Hype immediately of any breach of security or unauthorized use of your Account. As between you and
              Hype, you are solely responsible and liable for the activity that occurs in connection with your Account. If you wish to delete your Account you may do it from the account page on the
              dashboard or send an email request to Hype at. op@overpowered.tv. We will deal with your request in accordance with applicable law.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>6 Payments to Hype.</Highlight> Except as expressly set forth in the Terms, your general right to access and use the Site is currently for free, but Hype may in the future
              charge a fee for certain access or usage. You will not be charged for any such access or use of the Site unless you first agree to such charges, but please be aware that any failure to
              pay applicable charges may result in you not having access to some or all of the Site.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>7 Intellectual Property Rights.</Highlight>
              <SubSection>
                <Highlight>7.1 End User License.</Highlight> Subject to the terms and conditions of these Terms, we hereby grant you a personal, revocable, non-exclusive, non-sublicensable,
                non-assignable, non-transferable license ('<Highlight>License</Highlight>') to: (i) download, install and use the App (as applicable) on a computer, mobile telephone or any other
                device you are using to access the Service (the '<Highlight>Device</Highlight>'); and (ii) access and use the Services on that Device in accordance with these Terms.
              </SubSection>
              <SubSection>
                <Highlight>7.2 Content and Marks.</Highlight> The (i) content on the Services, including without limitation, the text, documents, articles, brochures, descriptions, products, software,
                graphics, photos, sounds, videos, interactive features, and services (collectively, the '<Highlight>Materials</Highlight>'), (ii) and User Submissions, as defined below (together with
                the Materials, the 'Content'), and (iii) the trademarks, service marks and logos contained therein ('<Highlight>Marks</Highlight>'), are the property of Hype and/or its licensors and
                may be protected by applicable copyright or other intellectual property laws and treaties. “Hype”, the Hype logo, and other marks are Marks of Hype or its affiliates. All other
                trademarks, service marks, and logos used on the Services are the trademarks, service marks, or logos of their respective owners. We reserve all rights not expressly granted in and to
                the Services and the Content.
              </SubSection>
              <SubSection>
                <Highlight>7.3 License Restrictions.</Highlight> You agree not to, and shall not permit any third party to: (i) sublicense, redistribute, sell, lease, lend or rent the Services; (ii)
                make the Services available over a network where it could be used by multiple devices owned or operated by different people at the same time; (iii) disassemble, reverse engineer,
                decompile, decrypt, or attempt to derive the source code of, the Services; (iv) copy (except for back-up purposes), modify, improve, or create derivative works of the Services or any
                part thereof; (v) circumvent, disable or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any content or that
                enforce limitations on use of the Services; (vi) remove, alter or obscure any proprietary notice or identification, including copyright, trademark, patent or other notices, contained
                in or displayed on or via the Services; (vii) use any communications systems provided by the App to send unauthorized and/or unsolicited commercial communications; (viii) use the Hype
                name, logo or trademarks without our prior written consent; and/or (ix) use the Services to violate any applicable laws, rules or regulations, or for any unlawful, harmful,
                irresponsible, or inappropriate purpose, or in any manner that breaches this Agreement.
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>8 Information Description.</Highlight> We attempt to be as accurate as possible. However, we cannot and do not warrant that the Content available on the Services is accurate,
              complete, reliable, current, or error-free. We reserve the right to make changes in or to the Content, or any part thereof, in our sole judgment, without the requirement of giving any
              notice prior to or after making such changes to the Content. Your use of the Content, or any part thereof, is made solely at your own risk and responsibility.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>9 Links.</Highlight>
              <SubSection>
                <Highlight>9.1</Highlight> The Services may contain links, and may enable you to post content, to third party websites that are not owned or controlled by Hype. We are not affiliated
                with, have no control over, and assume no responsibility for the content, privacy policies, or practices of, any third party websites. You: (i) are solely responsible and liable for
                your use of and linking to third party websites and any content that you may send or post to a third party website; and (ii) expressly release Hype from any and all liability arising
                from your use of any third party website. Accordingly, we encourage you to read the terms and conditions and privacy policy of each third party website that you may choose to visit.
              </SubSection>
              <SubSection>
                <Highlight>9.2</Highlight> Hype permits you to link to the Site provided that: (i) you link to but do not replicate any page on the Site; (ii) the hyperlink text shall accurately
                describe the Content as it appears on the Site; (iii) you shall not misrepresent your relationship with Hype or present any false information about Hype and shall not imply in any way
                that we are endorsing any services or products, unless we have given you our express prior consent; (iv) you shall not link from a website ('
                <Highlight>Third Party Website</Highlight>') which prohibits linking to third parties; (v) such Third Party Website does not contain content that (a) is offensive or controversial
                (both at our discretion), or (b) infringes any intellectual property, privacy rights, or other rights of any person or entity; and/or (vi) you, and your website, comply with these
                Terms and applicable law.
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>10 Privacy.</Highlight> We will use any personal information that we may collect or obtain in connection with the Services in accordance with our privacy policy which is
              available at{" "}
              <CustomLink fontWeight={600} underline="none" href="/p/privacy">
                hype.lol/p/privacy
              </CustomLink>
              . You agree that we may use personal information that you provide or make available to us in accordance with the Privacy Policy.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>11 Warranty Disclaimers.</Highlight>
              <SubSection>
                <Highlight>11.1</Highlight> This section applies whether or not the services provided under the Services are for payment. Applicable law may not allow the exclusion of certain
                warranties, so to that extent certain exclusions set forth herein may not apply.
              </SubSection>
              <SubSection>
                <Highlight>11.2</Highlight> THE SERVICES ARE PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS, AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. HYPE HEREBY DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY
                STATUTE OR FROM A COURSE OF DEALING OR USAGE OF TRADE. HYPE DOES NOT GUARANTEE THAT THE SERVICES WILL BE FREE OF BUGS, SECURITY BREACHES, OR VIRUS ATTACKS. THE SERVICES MAY
                OCCASIONALLY BE UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS. YOU AGREE THAT HYPE WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT
                MAY RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS. WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT,
                PRODUCT, OR SERVICE THAT IS FEATURED OR ADVERTISED ON THE SERVICES BY A THIRD PARTY.
              </SubSection>
              <SubSection>
                <Highlight>11.3</Highlight> HYPE DOES NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT THAT APPEARS IN A USER SUBMISSION, AND DOES NOT MAKE ANY REPRESENTATION OR WARRANTY WITH RESPECT TO,
                AND DISCLAIMS ALL LIABILITY FOR, ANY SUCH CONTENT.
              </SubSection>
              <SubSection>
                <Highlight>11.4</Highlight> YOU SPECIFICALLY ACKNOWLEDGE THAT HYPE SHALL NOT BE RESPONSIBLE FOR THE USER SUBMISSIONS OR CONDUCT (INCLUDING DEFAMATORY, OFFENSIVE, ILLEGAL, OR NEGLIGENT
                CONDUCT) OF ANY SERVICES USER AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
              </SubSection>
              <SubSection>
                <Highlight>11.5</Highlight> YOUR RELIANCE ON, OR USE OF, ANY USER SUBMISSION, OR INTERACTION WITH ANY SERVICES USER OR OWNER, IS AT YOUR SOLE RISK. IF YOU HAVE A DISPUTE WITH ANY
                SERVICES USER OR OWNER IN CONNECTION WITH THE SERVICES OR ANY USER SUBMISSION, YOU AGREE THAT HYPE IS NOT LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR CONNECTED WITH SUCH A
                DISPUTE. HYPE RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
              </SubSection>
              <SubSection>
                <Highlight>11.6</Highlight> EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, HYPE DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, AS TO
                THE SECURITY OF ANY INFORMATION YOU MAY PROVIDE OR ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR USE OF THE SERVICES.
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>12 Limitation of Liability.</Highlight>
              <SubSection>
                <Highlight>12.1</Highlight> TO THE FULLEST EXTENT PERMISSIBLE BY LAW, HYPE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL DAMAGES OF ANY
                KIND, OR FOR ANY LOSS OF DATA, REVENUE, PROFITS OR REPUTATION, ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF HYPE HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES OR LOSSES. Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitations may
                not apply to you.
              </SubSection>
              <SubSection>
                <Highlight>12.2</Highlight> IN NO EVENT SHALL THE AGGREGATE LIABILITY OF HYPE FOR ANY DAMAGES ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE SERVICES EXCEED
                THE TOTAL AMOUNT OF FEES, IF ANY, PAID BY YOU TO HYPE FOR USING THE SERVICES DURING THE THREE (3) MONTHS PRIOR TO BRINGING THE CLAIM.
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>13 Indemnity.</Highlight> You agree to defend, indemnify and hold harmless Hype and our affiliates, and our respective officers, directors, employees and agents, from and
              against any and all claims, damages, obligations, losses, liabilities, costs and expenses (including but not limited to attorney's fees) arising from: (i) your use of, or inability to
              use, the Services; (ii) your User Submissions; (iii) your interaction with any Services user; or (iv) your violation of these Terms.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>14 Updates and Upgrades.</Highlight> We may from time to time provide updates or upgrades to the App (each a '<Highlight>Revision</Highlight>'), but are not under any
              obligation to do so. Such Revisions will be supplied according to our then-current policies, which may include automatic updating or upgrading without any additional notice to you. You
              consent to any such automatic updating or upgrading of the App. All references herein to the App shall include Revisions thereto. This Agreement shall govern any Revisions that replace
              or supplement the original App, unless the Revision is accompanied by a separate license agreement which will govern the Revision.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>15 Third Party Open Source Software.</Highlight> Portions of the Services may include third party or open source software that is subject to third party terms and conditions
              ('<Highlight>Third Party Terms</Highlight>'). We are not responsible for any such software. If there is a conflict between any Third Party Terms and the terms of this Terms, then the
              Third Party Terms shall prevail but solely in connection with the related third party open source software..
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>16 Term and Termination.</Highlight> These Terms are effective until terminated by Hype or you. Hype, in its sole discretion, has the right to terminate these Terms and/or
              your access to the Services, or any part thereof, immediately at any time and with or without cause (including, without any limitation, for a breach of these Terms). Hype shall not be
              liable to you or any third party for termination of the Services, or any part thereof. If you object to any term or condition of these Terms, or any subsequent modifications thereto, or
              become dissatisfied with the Services in any way, your only recourse is to immediately discontinue use of the Services. Upon termination of these Terms, you shall cease all use of the
              Services. This Section 16 and Sections 7 (Intellectual Property Rights), 10 (Privacy), 11 (Warranty Disclaimers), 12 (Limitation of Liability), 13 (Indemnity), and 17 (Independent
              Contractors) to 20 (General) shall survive termination of these Terms.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>17 Independent Contractors.</Highlight> You and Hype are independent contractors. Nothing in these Terms creates a partnership, joint venture, agency, or employment
              relationship between you and Hype. You must not under any circumstances make, or undertake, any warranties, representations, commitments or obligations on behalf of Hype.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>18 Assignment.</Highlight> These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you but may be assigned by Hype without
              restriction or notification to you. Any prohibited assignment shall be null and void.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>19 Governing Law.</Highlight> Hype reserves the right to discontinue or modify any aspect of the Services at any time. These Terms and the relationship between you and Hype
              shall be governed by and construed in accordance with the laws of the State of Texas, United States, without regard to its principles of conflict of laws.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>20 General.</Highlight> These Terms shall constitute the entire agreement between you and Hype concerning the Services. If any provision of these Terms is deemed invalid by a
              court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.
              No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and a party's failure to assert any right or provision under these
              Terms shall not constitute a waiver of such right or provision. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE
              (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>Last updated: February, 2021</Highlight>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
