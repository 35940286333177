import React from "react";
import TermsOfService from "./pages/tos";
import PrivacyPolicy from "./pages/privacy";
import Whitelist from "./pages/list";
import NotFound from "./utils/NotFound";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

export default function Pages() {
  const { subPath } = useParams();

  return (
    <SimpleBar style={{ minHeight: 0, height: "100%" }}>
      {subPath === "tos" ? <TermsOfService /> : subPath === "privacy" ? <PrivacyPolicy /> : subPath === "whitelist" ? <Whitelist /> : <NotFound />}
    </SimpleBar>
  );
}
