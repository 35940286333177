import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomLink from "../utils/CustomLink";
import { Highlight, SubSection } from "./text";

export default function TermsOfService() {
  useEffect(() => {
    document.title = "Privacy Policy - Hype";
  }, []);

  return (
    <Box sx={{ ml: 2, mr: 2 }}>
      <Box sx={{ m: "0 auto", maxWidth: "100rem", p: 1 }}>
        <Box sx={{ mt: 3, textAlign: "center", mb: 3 }}>
          <Typography variant="h3" fontWeight={600}>
            Privacy Policy
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "54rem", m: "0 auto 50px" }}>
          <Box sx={{ color: "hsl(0 0% 100%/.8)!important", textAlign: "justify", fontWeight: "400", verticalAlign: "baseline" }}>
            <Typography mt={2} variant="body2">
              This privacy policy ('Privacy Policy') governs how we, Hype, and/or any other member of the Hype group (together, “Hype”, “we”, “our” or “us”) use, collect and store Personal Data we
              collect or receive from or about you (“User”, “you”) such as in the following use cases:
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>1</Highlight> When you browse or visit our website, https://hype.lol ('Website');
            </Typography>
            <Typography mt={2} variant="body2">
              <Highlight>2</Highlight> When you make use of, or interact with, our Website
              <SubSection>
                <Highlight>1.</Highlight> When you create an account and/or log in clicking on “Connect Twitch”
              </SubSection>
              <SubSection>
                <Highlight>2.</Highlight> When you download our software, and/or desktop app
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              We greatly respect your privacy, which is why we make every effort to provide a platform that would live up to the highest of user privacy standards. Please read this Privacy Policy
              carefully, so you can fully understand our practices in relation to Personal Data. “Personal Data” means any information that can be used, alone or together with other data, to uniquely
              identify any living human being. Please note that this is a master privacy policy and some of its provisions only apply to individuals in certain jurisdictions. For example, the legal
              basis in the table below is only relevant for GDPR-protected individuals.
            </Typography>
            <Typography mt={2} variant="body2">
              This Privacy Policy may be updated from time to time and therefore we ask you to check back periodically for the latest version of the Privacy Policy, as indicated below. If there will
              be any significant changes made to the use of your Personal Data in a manner different from that stated at the time of collection, we will notify you by posting a notice on our Website
              or by other means
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>1. WHAT INFORMATION WE COLLECT, WHY WE COLLECT IT, AND HOW IT IS USED</Highlight>
              <SubSection>
                <Highlight>1. When you make use of, or interact with our Website</Highlight>
                <SubSection>
                  <SubSection>
                    <Highlight>Data we Collect:</Highlight> Username, Email Address, Channel information
                  </SubSection>
                  <SubSection>
                    <Highlight>Why is the data collected and for what purposes? :</Highlight> Signing up and creating an account, To use our system features, such as making graphs, clip, and other
                    features we offer the user
                  </SubSection>
                  <SubSection>
                    <Highlight>Legal basis (GDPR only):</Highlight>Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request
                    of the data subject prior to entering into a contract. Legitimate interest (e.g. sign you up to our service)
                  </SubSection>
                  <SubSection>
                    <Highlight>Period of storage:</Highlight> Until we no longer need the information and proactively delete it or you send a valid deletion request. Please note that we may retain it
                    for a longer or shorter period in accordance with data retention laws
                  </SubSection>
                  <SubSection>
                    <Highlight>Consequences of not providing the data:</Highlight> Cannot sign up and create an account, Cannot use our system features
                  </SubSection>
                </SubSection>
              </SubSection>
              <SubSection>
                <Highlight>2. When you download our software, or app(s)</Highlight>
                <SubSection>
                  <SubSection>
                    <Highlight>Data we Collect:</Highlight> Username, Email Address, Channel information
                  </SubSection>
                  <SubSection>
                    <Highlight>Why is the data collected and for what purposes? :</Highlight> Download our software, and apps
                  </SubSection>
                  <SubSection>
                    <Highlight>Legal basis (GDPR only):</Highlight> Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the
                    request of the data subject prior to entering into a contract Legitimate interest (e.g. respond to a query sent by you)
                  </SubSection>
                  <SubSection>
                    <Highlight>Period of storage:</Highlight> Until we no longer need the information and proactively delete it or you send a valid deletion request. Please note that we may retain it
                    for a longer or shorter period in accordance with data retention laws
                  </SubSection>
                  <SubSection>
                    <Highlight>Consequences of not providing the data:</Highlight> Cannot download the software, or apps
                  </SubSection>
                </SubSection>
              </SubSection>
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>2. HOW WE SHARE YOUR PERSONAL DATA</Highlight>
            </Typography>
            <Typography mt={2} variant="body2">
              In addition to the recipients described in Section 1, we may share your information as follows:
              <SubSection>
                <Highlight>1.</Highlight> To the extent necessary, with regulators, to comply with all applicable laws, regulations and rules, and requests of law enforcement, regulatory and other
                governmental agencies or if required to do so by court order;{" "}
                <CustomLink href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" underline="none">
                  https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
                </CustomLink>
              </SubSection>
              <SubSection>
                <Highlight>2.</Highlight> Internal transfers: We ensure transfers within the Hype group will be covered by an agreement entered into by members of the Hype group (an intra-group
                agreement) which contractually obliges each member to ensure that Personal Data receives an adequate and consistent level of protection wherever it is transferred to;
              </SubSection>
              <SubSection>
                <Highlight>3.</Highlight> External transfers: Where we transfer your Personal Data outside of EU/EEA, for example to third parties who help provide our products and services, we will
                obtain contractual commitments from them to protect your Personal Data;
              </SubSection>
              <SubSection>
                <Highlight>4.</Highlight> Some of these assurances are well recognized certification schemes like the EU - US Privacy Shield for the protection of Personal Data transferred from within
                the EU to the United States; or
              </SubSection>
              <SubSection>
                <Highlight>5.</Highlight> Where we receive requests for information from law enforcement or regulators, we carefully validate these requests before any Personal Data is disclosed.
              </SubSection>
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>3. ADDITIONAL INFORMATION REGARDING TRANSFERS OF PERSONAL DATA</Highlight>
              <SubSection>
                <Highlight>1.</Highlight> To the extent necessary, with regulators, to comply with all applicable laws, regulations and rules, and requests of law enforcement, regulatory and other
                governmental agencies or if required to do so by court order;
              </SubSection>
              <SubSection>
                <Highlight>2.</Highlight> If, in the future, we sell or transfer some or all of our business or assets to a third party, we will (to the minimum extent required) disclose information
                to a potential or actual third party purchaser of our business or assets. In the event that we are acquired by or merged with a third party entity, or in the event of bankruptcy or a
                comparable event, we reserve the right to transfer or assign Personal Data in connection with the foregoing events.
              </SubSection>
              <SubSection>
                <Highlight>3.</Highlight> Where you have provided your consent to us sharing the Personal Data (e.g., where you provide us with marketing consents or opt-in to optional additional
                services or functionality); and
              </SubSection>
              <SubSection>
                <Highlight>4.</Highlight> Where we receive requests for information from law enforcement or regulators, we carefully validate these requests before any Personal Data is disclosed.
              </SubSection>
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>3. ADDITIONAL INFORMATION REGARDING TRANSFERS OF PERSONAL DATA</Highlight>
            </Typography>
            <Typography mt={2} variant="body2">
              The following rights (which may be subject to certain exemptions or derogations), shall apply to certain individuals (some of which only apply to individuals protected by the GDPR):
              <SubSection>
                <Highlight>1.</Highlight> You have a right to access information held about you. Your right of access is normally be exercised free of charge, however we reserve the right to charge an
                appropriate administrative fee where permitted by applicable law;
              </SubSection>
              <SubSection>
                <Highlight>2.</Highlight> You have the right to request that we amend any Personal Data we hold that it is inaccurate or misleading
              </SubSection>
              <SubSection>
                <Highlight>3.</Highlight> You have the right to request the erasure of the Personal Data that relates to you. Please note that there may be circumstances in which we are required to
                retain your data, for example for the establishment, exercise or defense of legal claims;
              </SubSection>
              <SubSection>
                <Highlight>4.</Highlight> The right to object to or to request restriction of the processing. However, there may be circumstances in which we are legally entitled to refuse your
                request;
              </SubSection>
              <SubSection>
                <Highlight>5.</Highlight> The right to data portability. This means that you may have the right to receive your Personal Data in a structured, commonly used and machine-readable
                format, and that you have the right to transmit that data to another controller; You have the right to object to profiling;
              </SubSection>
              <SubSection>
                <Highlight>6.</Highlight> You have the right to object to profiling;
              </SubSection>
              <SubSection>
                <Highlight>7.</Highlight> You have a right to lodge a complaint with your local data protection supervisory authority (i.e., your place of habitual residence, place or work or place of
                alleged infringement) at any time. We ask that you please attempt to resolve any issues with us before you contact your local supervisory authority
              </SubSection>
              <SubSection>
                <Highlight>8.</Highlight> The right to withdraw your consent. Please note that there may be circumstances in which we are entitled to continue processing your data, in particular if
                the processing is required to meet our legal and regulatory obligations.
              </SubSection>
              <SubSection>
                <Highlight>9.</Highlight> You also have a right to request details of the basis on which your Personal Data is transferred outside the European Economic Area, but you acknowledge that
                data transfer agreements may need to be partially redacted for reasons of commercial confidentiality
              </SubSection>
            </Typography>
            <Typography mt={2} variant="body2">
              You can exercise your rights by contacting us at op@overpowered.tv. Subject to legal and other permissible considerations, we will make every reasonable effort to honor your request
              promptly or inform you if we require further information in order to fulfil your request. When processing your request, we may ask you for additional information to confirm your identity
              and for security purposes, before disclosing the Personal Data requested to you. We reserve the right to charge a fee where permitted by law, for instance if your request is manifestly
              unfounded or excessive.
            </Typography>
            <Typography mt={2} variant="body2">
              In the event that your request would adversely affect the rights and freedoms of others (for example, would impact the duty of confidentiality we owe to others) or if we are legally
              entitled to deal with your request in a different way than initial requested, we will address your request to the maximum extent possible, all in accordance with applicable law.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>5. USE BY CHILDREN</Highlight> The services are not structured to attract children under the age of 13 years. Accordingly, we do not intend to collect Personal Information
              from anyone we know to be under 13 years. If we learn that we have collected Personal Information from a child under 13 years, we will delete that information as quickly as possible. If
              you believe that we might have any such information, please contact us at op@overpowered.tv. Some of your activity on and through the Services is public by default. This may include, but
              is not limited to, content you have posted publicly on the Website or otherwise through the Services. Registered users may have some of this information associated with their Accounts.
              Unregistered users will not have this association, but information concerning their use of the Services (such as what pages they have visited) may be tracked anonymously through the use
              of cookies and stored by us. Please also remember that if you choose to provide Personal Information using certain public features of the Services, then that information is governed by
              the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our
              control and without your knowledge, and search engines may index that information. We therefore urge you to think carefully about including any specific information you may deem private
              in content that you create or information that you submit through the Services.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>6. HOW CAN I DELETE MY ACCOUNT?</Highlight> Should you ever decide to delete your Account, you may do so by emailing op@overpowered.tv. If you terminate your Account, any
              association between your Account and information we store will no longer be accessible through your Account. However, given the nature of sharing on the Services, any public activity on
              your Account prior to deletion will remain stored on our servers and will remain accessible to the public. In addition to our normal procedure for stored data deletion, you can revoke
              our access to your data via the Twitch connections settings page
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>7. LINKS TO AND INTERACTION WITH THIRD PARTY PRODUCTS</Highlight> The Website may enable you to interact with or contain links to your Third Party Account and other third
              party websites, mobile software applications and services that are not owned or controlled by us (each a “Third Party Service”). We use the Twitch API Services. We are not responsible
              for the privacy practices or the content of such Third Party Services. Please be aware that Third Party Services may collect Personal Information from you. Accordingly, we encourage you
              to read the terms and conditions and privacy policy of each Third Party Service that you choose to use or interact with.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>8. LOG FILES</Highlight> We may make use of log files. The information inside the log files includes internet protocol (IP) addresses, type of browser, Internet Service
              Provider (ISP), date/time stamp, referring/exit pages, clicked pages and any other information your browser may send to us. We may use such information to analyze trends, administer the
              Website, track users’ movement around the Website, and gather demographic information.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>9. COOKIES AND OTHER TRACKING TECHNOLOGIES</Highlight> Our Website may utilize “cookies”, anonymous identifiers and other tracking technologies in order to for us to provide
              our Website and present you with information that is customized for you. A “cookie” is a small text file that may be used, for example, to collect information about activity on the
              Website. Certain cookies and other technologies may serve to recall Personal Information, such as an IP address, previously indicated by a user. Most browsers allow you to control
              cookies, including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your
              browser.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>10. ANALYTIC TOOLS</Highlight>
              <SubSection>
                <Highlight>1.</Highlight> Google Analytics. The Website may use a tool called “Google Analytics” to collect information about use of the Website. Google Analytics collects information
                such as how often users visit this Website, what pages they visit when they do so, and what other sites they used prior to coming to this Website. We use the information we get from
                Google Analytics to maintain and improve the Website and our products. We do not combine the information collected through the use of Google Analytics with personally identifiable
                information. Google’s ability to use and share information collected by Google Analytics about your visits to this Website is restricted by the Google Analytics Terms of Service,
                available at http://www.google.com/analytics/terms/us.html, and the Google Privacy Policy, available at{" "}
                <CustomLink underline="none" href="http://www.google.com/policies/privacy">
                  http://www.google.com/policies/privacy
                </CustomLink>
                . You may learn more about how Google collects and processes data specifically in connection with Google Analytics at{" "}
                <CustomLink underline="none" href="http://www.google.com/policies/privacy/partners">
                  http://www.google.com/policies/privacy/partners
                </CustomLink>
                . You may prevent your data from being used by Google Analytics by downloading and installing the Google Analytics Opt-out Browser Add-on, available at{" "}
                <CustomLink underline="none" href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </CustomLink>
                .
              </SubSection>
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>11. CALIFORNIA PRIVACY RIGHTS</Highlight> California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding our
              disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to op@overpowered.tv. Please note that we are only
              required to respond to one request per customer each year.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>12. OUR CALIFORNIA DO NOT TRACK NOTICE</Highlight> We do not currently respond or take any action with respect to web browser “do not track” signals or other mechanisms that
              provide consumers the ability to exercise choice regarding the collection of personally identifiable information about an individual consumer’s online activities over time and across
              third-party websites or online services. We may allow third parties, such as companies that provide us with analytics tools, to collect personally identifiable information about an
              individual consumer's online activities over time and across different websites when a consumer uses the Services..
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>13. DELETION OF CONTENT FROM CALIFORNIA RESIDENTS</Highlight> If you are a California resident under the age of 18 and a registered user, California Business and Professions
              Code Section 22581 permits you to remove content or Personal Information you have publicly posted. To remove, please send an email to op@overpowered.tv. Please be aware that after
              removal you will not be able to restore removed content. In addition, such removal does not ensure complete or comprehensive removal of the content or Personal Information you have
              posted and that there may be circumstances in which the law does not require us to enable removal of content.
            </Typography>

            <Typography mt={2} variant="body2">
              <Highlight>14. CONTACT US</Highlight> If you have any questions, concerns or complaints regarding our compliance with this notice and the data protection laws, or if you wish to exercise
              your rights, we encourage you to first contact us at op@overpowered.tv.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
