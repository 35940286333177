import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Highlight } from "./text";

export default function Whitelist() {
  const [whitelist, setWhitelist] = useState();

  useEffect(() => {
    document.title = `Whitelist - Hype`;
    const fetchWhitelist = async () => {
      await fetch("https://api.hype.lol/v1/whitelist", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const sortedArray = data.sort((a, b) => {
            return a.channel.toLowerCase().localeCompare(b.channel.toLowerCase());
          });
          setWhitelist(sortedArray);
        })
        .catch((e) => {
          console.error(e);
        });
    };
    fetchWhitelist();
    return;
  }, []);

  if (!whitelist) return <></>;

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 2 }}>
      <Typography variant="h4">
        There are currently <Highlight>{whitelist.length}</Highlight> whitelisted channels!
      </Typography>
      <Box sx={{ mt: 2 }}>
        {whitelist.map((res, i) => {
          return (
            <Typography key={i} variant="body1">
              {res.channel}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
