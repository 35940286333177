import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import logo from "./assets/logo.svg";
import SimpleBar from "simplebar-react";
import Footer from "./utils/Footer";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SearchIcon from "@mui/icons-material/Search";
import TheatersIcon from "@mui/icons-material/Theaters";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ChatIcon from "@mui/icons-material/Chat";

export default function Frontpage() {
  return (
    <SimpleBar style={{ minHeight: 0, height: "100%" }}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <img alt="" src={logo} width="400rem" />
          <Typography sx={{ mt: 3 }} variant="h3">
            Finding The Hype
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ mt: 5, justifyContent: "center" }}>
          <Grid item xs={2} sx={{ maxWidth: "18rem", flexBasis: "18rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ maxWidth: "12rem", textAlign: "center" }}>
              <QueryStatsIcon sx={{ height: "80px", width: "80px" }} color="primary" />
              <Typography mt={1} variant="h6">
                Graphs
              </Typography>
              <Typography mt={1} variant="caption">
                Get detailed graphs to find Hype moments. Clip discovery made easy!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "18rem", flexBasis: "18rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ maxWidth: "12rem", textAlign: "center" }}>
              <SearchIcon sx={{ height: "80px", width: "80px" }} color="primary" />
              <Typography mt={1} variant="h6">
                Search
              </Typography>
              <Typography mt={1} variant="caption">
                Search for a keyword and find when Pepega happens the most in the vod!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "18rem", flexBasis: "18rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ maxWidth: "12rem", textAlign: "center" }}>
              <TheatersIcon sx={{ height: "80px", width: "80px" }} color="primary" />
              <Typography mt={1} variant="h6">
                Download Vods
              </Typography>
              <Typography mt={1} variant="caption">
                Download Whole Vods (Source, 720p60, 720p30, and Audio)!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "18rem", flexBasis: "18rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ maxWidth: "12rem", textAlign: "center" }}>
              <ContentCutIcon sx={{ height: "80px", width: "80px" }} color="primary" />
              <Typography mt={1} variant="h6">
                Clipping
              </Typography>
              <Typography mt={1} variant="caption">
                Make clips with no quality loss & no max duration! Clipping is FAST and EZ!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "18rem", flexBasis: "18rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ maxWidth: "12rem", textAlign: "center" }}>
              <ChatIcon sx={{ height: "80px", width: "80px" }} color="primary" />
              <Typography mt={1} variant="h6">
                Chat
              </Typography>
              <Typography mt={1} variant="caption">
                Get a transparent chat overlay included with your clip! Your time is more valuable than recording a screen!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer sx={{ mt: 5 }} />
    </SimpleBar>
  );
}
