import { styled } from "@mui/material";

const Highlight = styled((props) => <span {...props} />)`
  font-weight: 600;
  color: #fff;
`;

const SubSection = styled((props) => <span {...props} />)`
  display: block;
  margin-top: 1rem;
  margin-left: 1rem;
`;

export { Highlight, SubSection };
