import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Frontpage from "./frontpage";
import client from "./client";
import Settings from "./settings";
import NavBar from "./navbar";
import Pages from "./pages";
import Redirect from "./utils/Redirect";
import NotFound from "./utils/NotFound";

export default function App() {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    client.authenticate().catch(() => setUser(null));

    client.on("authenticated", (paramUser) => {
      setUser(paramUser.user);
    });

    client.service("users").on("patched", (paramUser) => {
      if (!user || !paramUser) return;
      if (paramUser.id === user.id) {
        client
          .service("users")
          .get(user.id)
          .then((user) => {
            setUser(user);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });

    client.on("logout", () => {
      setUser(null);
      window.location.href = "/";
    });

    return;
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          exact
          path="/"
          element={
            <>
              <NavBar user={user} />
              <Frontpage user={user} />
            </>
          }
        />
        <Route
          exact
          path="/p/:subPath"
          element={
            <>
              <NavBar user={user} />
              <Pages />
            </>
          }
        />
        <Route exact path="/settings" element={<Redirect to="/settings/profile" />} />
        <Route
          exact
          path="/settings/:subPath"
          element={
            <>
              <NavBar user={user} />
              <Settings user={user} />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
